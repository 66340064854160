import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

import MainBanner from "@components/hairstraightener/mainBanner";
import HairStraightenerWrapper from "@components/layouts/hairstraightenerWrapper";
const OffersList = dynamic(() => import("@components/shared/offersList"));
const UterineCancer = dynamic(
    () => import("@components/hairstraightener/uterineCancer"),
);
const Symptoms = dynamic(() => import("@components/hairstraightener/symptoms"));

export default function HairStraightenerHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <HairStraightenerWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <UterineCancer />
            <Symptoms />
        </HairStraightenerWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
